<template>
  <div>
    <NuxtLayout>
      <QuerySearch class="sm:hidden pb-4" @showFilterOverlay="isFilterModalOpen = true"/>
      <nav class="flex w-full bg-gray-100 dark:bg-gray-800 p-1 rounded-lg transition gap-x-1">
        <ULink
            :to="route.fullPath"
            active-class="bg-white dark:bg-gray-900"
            class="relative inline-flex items-center justify-center w-full py-2 px-3 rounded-md text-sm font-medium transition-colors dark:text-white"
            exact-query
            @click="isFilterModalOpen = true"
        >
          <span v-if="filterCount">Filtr ({{ filterCount }})</span>
          <span v-else>Wszystko</span>
        </ULink>
        <ULink
            v-for="(category) in categoryRepository.find().items"
            :to="`/?filter[category]=${category.id}`"
            active-class="bg-white dark:bg-gray-900"
            class="relative inline-flex items-center justify-center w-full py-2 px-3 rounded-md text-sm font-medium transition-colors dark:text-white"
            exact-query
        >
          {{ category.name }}
        </ULink>
      </nav>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 h-full mt-4">
        <div v-if="pending" v-for="foo in [1,2,3]">
          <div>
            <div class="relative w-full h-[200px]">
              <USkeleton class="w-full h-full"/>
            </div>
            <div class="flex mt-2 space-x-4">
              <USkeleton class="w-1/3 h-[36px]"/>
              <USkeleton class="w-1/3 h-[36px]"/>
            </div>
            <p class="mt-2 text-md text-gray-500 dark:text-gray-400 line-clamp-2">
              <USkeleton class="mt-1 w-2/3 h-[16px]"/>
              <USkeleton class="mt-2 w-3/3 h-[16px]"/>
              <USkeleton class="mt-1 w-1/3 h-[16px]"/>
            </p>
            <USkeleton class="mt-4 w-full h-[40px]"/>
          </div>
        </div>
        <div v-else v-for="(post, index) in posts" class="mb-4">
          <ULink :to="`/posts/${post.id}/`" class="flex relative w-full h-[200px]">
            <UBadge class="absolute top-4 right-4 z-20 gap-x-2" color="white">{{ post.images.length }}
              <UIcon name="i-ri-gallery-line"/>
            </UBadge>
            <NuxtImg
                :alt="`Zdjęcie #${index} ${post.title}`"
                :src="post.images[0]"
                class="absolute z-10 w-full h-full object-cover rounded-t-lg"
                provider="znlzn"
                width="400"
            />
          </ULink>
          <div class="flex mt-4 space-x-2 truncate">
            <UButton
                :aria-label="post.category.name"
                :color="'N3WkKxAq8kqfyuhBAMsmqu' === post.category.id ? 'primary' : 'red'"
                :label="post.category.name"
                :to="`/?filter[category]=${post.category.id}`"
                size="sm"
                variant="outline"
            />
            <UButton
                v-if="post.location"
                :aria-label="post.location.city"
                :label="post.location.city"
                :to="`/?filter[city]=${post.location.city}`"
                color="sky"
                size="sm"
                variant="outline"
            />
            <UButton
                :aria-label="$dayjs(post.occurredAt).fromNow()"
                :label="$dayjs(post.occurredAt).fromNow()"
                :to="`/?filter[occurredAt][from]=${post.occurredAt}&filter[occurredAt][to]=${post.occurredAt}\``"
                color="white"
                size="md"
                variant="soft"
            />
          </div>
          <h2 class="mt-2 text-xl sm:text-lg font-extrabold text-gray-800 dark:text-white line-clamp-1">{{
              post.title
            }}</h2>
          <p class="mt-2 text-md text-gray-500 dark:text-gray-400 line-clamp-2">
            {{ post.content }}
          </p>
          <UButton
              class="mt-4"
              :to="`/posts/${post.id}/`"
              block
              label="Zobacz więcej"
              aria-label="Zobacz więcej"
              size="lg"
          />
        </div>
      </div>
      <LazyUSlideover v-model="isFilterModalOpen" side="right">
        <LazyFilterSidebar :is-overlay="true" @close-overlay="isFilterModalOpen = false"/>
      </LazyUSlideover>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import QuerySearch from "~/components/QuerySearch.vue";
import CategoryRepository from "~/repositories/CategoryRepository";

const categoryRepository = new CategoryRepository();

const route = useRoute();

const filterCount = computed(() => {
  return Object.keys(route.query)
      .filter((k) => k.startsWith('filter['))
      .length;
});

const {data: rposts, pending} = await useApi(
    '/rest/public/posts',
    {
      query: computed(() => route.query),
    }
);

const {data: rlocations} = await useApi(
    '/rest/public/addresses', {
      query: {
        'pagination[offset]': 0,
        'pagination[limit]': rposts.value.items.length,
        'filter[cid][]': computed(() => rposts.value.items.map((post) => post.location).filter(Boolean))
      }
    });

const posts = ref(
    computed(() => rposts.value.items.map((post) => {
          return {
            ...post,
            location: rlocations.value.items.find((location) => location.id === post.location),
            category: categoryRepository.findOneById(post.category),
          }
        })
    )
);

const isFilterModalOpen = ref(false);

useSeoMeta({
  title: 'Nie trać rzeczy i czasu!',
})
</script>
